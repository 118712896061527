@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto; /* fallback */
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-size: 28px;
}

@layer components {
  .input {
    @apply h-12 border-2 rounded-md leading-[48px] px-4;
  }
  .wrapper {
    @apply flex flex-col w-[1000px] m-auto items-center gap-4 mt-10;
  }
}

input:disabled {
  background-color: #eeeeee;
}

input[type='date'],
input[type='time'] {
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

#ellipseDot{
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* no of lines */
  text-overflow: ellipsis;
  overflow:hidden !important;
  /* width:180px; */
}
